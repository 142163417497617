<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE GENERAL JOURNAL DATA</title>
    <section class="content-header">
      <h1>
        Update General Journal Data
        <br />
        <h4>Please edit the general journal accounting data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">General Journal Accounting</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-edit"></i>
              <h3 class="box-title">Update General Journal Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Number of evidence</label>
                  <input
                    type="text"
                    v-model="nobukti"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxjurnalh"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Evidence Date </label>
                  <input
                    type="date"
                    v-model="tglbukti"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    v-model="keterangan"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/jurnalumum">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updtdt",
  data() {
    return {
      loading: false,
      nobukti: "",
      idxjurnalh: "",
      tglbukti: "",
      keterangan: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/getjurnal_umum_hbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "jurnal_umum_h/getjurnal_umum_hbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxjurnalh = resp.data.data.id;
          this.nobukti = resp.data.data.no_bukti;
          this.tglbukti = resp.data.data.tanggal_bukti;
          this.keterangan = resp.data.data.keterangan;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var nobuk = this.nobukti;
      var idxjurnalhead = this.idxjurnalh;
      var tglbuk = this.tglbukti;
      var ket = this.keterangan;
      if (nobuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Number of evidence can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglbuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Evidence Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Description can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          no_bukti: this.nobukti,
          tanggal_bukti: this.tglbukti,
          keterangan: this.keterangan,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/jurnal_umum_h/updatejurnal_umum_h/"+ idxjurnalhead;
        const urlAPIUpdate = this.$apiurl + "jurnal_umum_h/updatejurnal_umum_h/" + idxjurnalhead;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "jurnalumumheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "jurnalumumheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
